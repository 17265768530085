import Link from 'components/Link/Link';
import type { LinkProps } from 'next/link';
import { isNextjsRoutable, resolve } from 'util/url';

export function InternalLinkComponent({ href, ...props }: { href: string } & Omit<LinkProps, 'href'>) {
  if (isNextjsRoutable(href)) {
    return <Link href={href} {...props} />;
  }
  return <a href={href} {...props} />;
}

export function ExternalLinkComponent({ href, ...props }: React.PropsWithChildren<LinkProps>) {
  try {
    const u = new URL(href.toString());
    if (['huutokaupat.com', 'huutokaupat.dev'].includes(u.host) && isNextjsRoutable(u.pathname)) {
      // Change all "external" links pointing to huutokaupat.com to relative paths
      return <Link href={resolve(href.toString())} {...props} target={undefined} rel={undefined} />;
    }
    return <a data-external href={resolve(href.toString())} {...props} />;
  } catch {
    return <a data-external href={href.toString()} {...props} />;
  }
}
