import { useEffect } from 'react';
import { gtmService } from '../util/gtm-service';
import { getConsentData, consentCookie } from '../util/consent-service';

export const useGtm = function () {
  useEffect(() => {
    gtmService.initializeConsentToGtm();

    // Check if consent preferences are already set and update them to GTM
    const consentData = getConsentData(consentCookie, false);

    if (consentData.length > 0) {
      gtmService.updateConsentToGtm(consentData);
    }
  }, []);
};
