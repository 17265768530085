import React from 'react';
import { PrismicLink } from '@prismicio/react';
import type { RichTextMapSerializer } from '@prismicio/richtext';
import { ExternalLinkComponent } from './linkComponents';

const richTextComponents: RichTextMapSerializer<JSX.Element> = {
  hyperlink: ({ children, node }) => {
    const { link_type, url } = node.data;

    if (link_type === 'Web') {
      return <ExternalLinkComponent href={url}>{children}</ExternalLinkComponent>;
    }
    return <PrismicLink field={node.data}>{children}</PrismicLink>;
  },
};

export default richTextComponents;
