'use client';

import { datadogRum } from '@datadog/browser-rum';
import { getStatisticsConsent } from '../../util/consent-service';

if (typeof window !== 'undefined') {
  const isProduction = /huutokaupat.com\//.test(window.location.href);
  const isConsentGiven = getStatisticsConsent();

  if (isProduction && isConsentGiven) {
    datadogRum.init({
      applicationId: '3dcdce55-3c2f-40fc-811e-8cdfa05aa230',
      clientToken: 'pub7e1e55cf25b17736d823820d83140b88',
      site: 'datadoghq.eu',
      service: 'huutokaupat-frontend',
      env: 'prod',
      version: process.env.NEXT_PUBLIC_VERSION,
      sessionSampleRate: 0.5,
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      allowedTracingUrls: ['https://huutokaupat.com'],
    });
  }
}

export function DatadogInit() {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}
